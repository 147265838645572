.header {
  background-color: $background-light;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;

  @include large {
    flex-direction: row;
  }

  &__button {
    margin-bottom: 12px; // 20px default - 8px from the box-shadow
    margin-left: auto;

    @include small {
      display: none;
    }
  }

  &__link {
    margin: 0.75em;
    color: $second-accent;
    text-transform: uppercase;

    @include large {
      display: flex;
      align-items: center;
    }

    &:hover {
      color: $accent;
      text-decoration: underline;
    }

    &--small-only {
      @include large {
        display: none;
      }
    }
  }

  &__navburger {
    // double the font size, half the margin, to imcrease clickability keeping the same box size
    font-size: 2em;
    margin: 0 0.3625em;
    color: $second-accent;
    user-select: none;

    @include large {
      display: none;
    }
  }

  &__navtoggle {
    display: none;
  }

  &__navtoggle:not(:checked) ~ &__link {
    @include small {
      display: none;
    }
  }
}
