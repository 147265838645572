.section-title {
  margin: 1em;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.9rem;
  color: $second-accent;
}

.section-subheading {
  margin: 1em;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  color: $white;
}
