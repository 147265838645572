.people {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 6vw;
  text-align: center;

  &__list {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 50px;
  }

  &__person {
    margin: 10px;
    max-width: 280px;

    &--small {
      max-width: 160px;
    }
  }

  &__photo {
    border: solid $people-accent 15px;
    border-radius: 50%;
  }

  &__name {
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.2rem;
    color: $second-accent;

    &--small {
      font-size: 0.8rem;
    }
  }

  &__mini-description {
    color: $white;
    font-size: 1.2rem;

    strong {
      color: $accent;
    }

    &--small {
      font-size: 0.8rem;
    }
  }

  &__call-for-papers {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 50px;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include medium {
      max-width: 40vw;
    }

    @include large {
      width: 460px;
    }
  }
}
