@mixin medium {
  @media (min-width: #{$medium-width}) {
    @content;
  }
}

@mixin large {
  @media (min-width: #{$large-width}) {
    @content;
  }
}

@mixin small {
  @media (max-width: #{$large-width - 1px}) {
    @content;
  }
}
