.organization {
  padding: 0 6vw;
  display: flex;
  justify-content: center;
  flex-flow: wrap;

  &__title {
    flex: 1 0 100%;
  }

  &__logo {
    margin: 1em;
    border-radius: 5px;
  }
}
