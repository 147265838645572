.cover {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 6vw;
  text-align: center;

  &__title {
    margin-top: 30px;
    align-self: center;

    @include large {
      max-width: 70%;
    }
  }

  &__past-editions {
    width: 100%;
    margin: 30px 0 50px 0;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include medium {
      max-width: 40vw;
    }

    @include large {
      width: 460px;
    }
  }
}
