.coc {
  padding: 0 6vw 13vh;
  text-align: center;

  @include medium {
    padding: 0 6vw 8vw;
  }

  &__title {
    display: inline-block;
  }
}
