.tickets {
  padding: 0 6vw;
  overflow: auto; // display: flow-root; is not supported in iOS Safari :(
  text-align: center;

  &__widget {
    margin: 0 auto;
  }

  &__description {
    margin-bottom: 2.5em;
  }
}
