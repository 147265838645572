.schedule {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 6vw;
  text-align: center;

  &__subtitle {
    margin: 1em;
    font-weight: bold;
    text-transform: uppercase;
    color: $white;
  }

  &__subheading {
    color: $second-accent;
  }

  &__activity {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    font-size: 1.2rem;
    text-align: left;
    display: flex;
    align-items: center;
    border-radius: 4px;
    color: $white;
  }

  &__activity-time {
    margin-right: 20px;
  }

  &__activity:nth-of-type(odd) {
    background-color: $background-light;
  }

  &__activity-tag {
    margin: 0 10px;
    padding: 5px;
    border-radius: 5px;
    font-size: 0.7rem;
    color: $background-dark;

    &[type="talk"] {
      background-image: linear-gradient(to right, #faa99d, adjust-hue(#faa99d, 60deg));
    }

    &[type="mini"] {
      background-image: linear-gradient(to right, #fddf7e, adjust-hue(#fddf7e, 60deg));
    }

    &[type="keynote"] {
      background-image: linear-gradient(to right, #9bfbe1, adjust-hue(#9bfbe1, 60deg));
    }

    &[type="beginner"] {
      background-image: linear-gradient(to right, #67ebfa, adjust-hue(#67ebfa, 60deg));
    }

    &[type="advanced"] {
      background-image: linear-gradient(to right, #ce97fb, adjust-hue(#ce97fb, 60deg));
    }

    &[type="all"] {
      background-image: linear-gradient(to right, #80df28, adjust-hue(#80df28, 60deg));
    }
  }
}
