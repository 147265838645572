@import "../variables/button_size";

/*
  The button can be implemented with the following html
  <a class="button"><span>Button text</span></a>

  Important to notice that the text is wrapped by a span, the main tag can
  be any other tag.

  To change the button color you should use a modifier like
  .module-name__button {
    color: $TEXT-COLOR;
    background-color: $MAIN-COLOR;
    box-shadow: $button-shadow-size $button-shadow-size $SHADOW-COLOR;
  }
*/
.button {
  min-height: 9vh;
  padding: 10px 25px;
  margin: 15px 15px 10px 15px;
  display: flex;
  justify-content: center;
  color: $white;
  background-image: linear-gradient(to right, $blackpink, adjust-hue($blackpink, 60deg));
  border-radius: 5px;

  font-size: 1.3rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;

  @include medium {
    min-height: 5vh;
    margin: 20px 20px 30px 20px;
    padding: 10px 30px;
  }

  span {
    align-self: center;
  }

  &--external {
    color: $background-dark;
    background-image: linear-gradient(to right, $accent, adjust-hue($accent, 60deg));
  }

  &--call-to-action {
    align-items: center;

    @include medium {
      padding: 25px 90px;
      align-self: center;
    }
  }
}
