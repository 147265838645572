@import "variables/breakpoints";
@import "variables/colors";
@import "common/mixins";

@import "reset";
@import "common/fonts";
@import "common/button";
@import "common/section-title";
@import "common/description";
@import "common/video-wrapper";
@import "common/background";

@import "modules/header";
@import "modules/cover";
@import "modules/people";
@import "modules/cfp";
@import "modules/schedule";
@import "modules/tickets";
@import "modules/not-found";
@import "modules/sponsors";
@import "modules/organization";
@import "modules/footer";
@import "modules/coc";

html {
  scroll-behavior: smooth;
  scroll-padding-top: 70px; // header's height + some breathing space
}
