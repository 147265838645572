.description {
  color: $white;
  margin: 0.5em 0;
  font-size: 1.2rem;

  a,
  strong {
    color: $accent;
  }

  &--fade {
    color: $grape-dark;
  }
}
