.not-found {
  background-color: $background-dark;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-evenly;
  align-items: center;

  @include medium {
    min-height: 90vh;
    flex-direction: row-reverse;
  }

  &__subheading {
    @include medium {
      margin-bottom: 80px;
    }
  }

  &__whererugoing {
    height: 30vh;
    margin: 30px 10px 0 10px;
    object-fit: contain;

    @include medium {
      height: 50vh;
    }

    @include large {
      height: 60vh;
    }
  }
}
