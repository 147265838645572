.background {
  &__first {
    background: linear-gradient($background-dark, $transition-two);
  }

  &__second {
    background: linear-gradient($transition-two, $transition-tree);
  }

  &__third {
    background: linear-gradient($transition-tree, $transition-four);
  }

  &__fourth {
    background: url(/assets/images/pattern-background.svg) repeat space,
      linear-gradient($transition-four, $transition-five);
    background-size: 300px;
    background-position: -5%;
  }

  &__fifth {
    background: url(/assets/images/pattern-background.svg) repeat space,
      linear-gradient($transition-five, $background-light);
    background-size: 300px;
    background-position: -5%;
  }
}
