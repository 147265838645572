.sponsors {
  padding: 4em 6vw;
  overflow: auto; // display: flow-root; is not supported in iOS Safari :(
  text-align: center;

  &__tier {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;

    &-title {
      font-size: 1.8em;
      font-weight: bold;
      margin-bottom: 1em;
      color: $second-accent;
    }
  }

  &__content {
    margin: 0 auto;

    @include medium {
      width: 80%;
    }

    @include large {
      width: 60%;
    }
  }

  &__logo {
    vertical-align: middle;
    object-fit: contain;

    &--super {
      display: flex;
      width: 100%;
      margin: 30px 0;

      @include medium {
        width: 46%;
        margin: 30px 2%;
      }

      @include large {
        width: 42%;
        margin: 2% 4%;
      }
    }

    &--standard {
      display: flex;
      width: 100%;
      margin: 30px 0;
      padding: 0 30px;

      @include medium {
        width: 40%;
        margin: 30px 5%;
      }

      @include large {
        width: 34%;
        margin: 2% 8%;
        padding: auto;
      }
    }

    &--apoio {
      display: flex;
      width: 100%;
      margin: 30px 0;

      @include medium {
        width: 34%;
        margin: 30px 8%;
      }

      @include large {
        width: 30%;
        margin: 2% 10%;
        padding: auto;
      }
    }
  }

  &__button {
    width: max-content;
    margin: 2em auto;
  }
}
