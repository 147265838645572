$background-light: #685887;
$background-dark: #302654;
$grape-dark: #9562e2;
$accent: #74f9fc;
$second-accent: #b1f45f;
$blackpink: #e272ca;
$white: #fefefe;
$people-accent: #d9b6f9;

// Background transitions
$transition-one: #302654;
$transition-two: #4e3a94;
$transition-tree: #583db8;
$transition-four: #4a398a;
$transition-five: #366397;
$transition-six: #6f53d3;
